const CREATOR = document.createElement('div');

class HTMLCreator {
	create(html, appendTo) {
		CREATOR.innerHTML = html.trim();
		const children = Array.from(CREATOR.children);
		const totalChilds = children.length;

		if (appendTo && totalChilds) {
			for (let k = 0; k < totalChilds; k++) {
				appendTo.appendChild(children[k]);
			}
		}
		return totalChilds === 1 ? children[0] : children;
	}
}

export default new HTMLCreator();
