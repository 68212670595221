class ScrollFadeOuts {
	constructor() {
		this.targets = [];
		this.update(true);

		window.addEventListener('scroll', (e) => {
			this.manageScroll();
		});
		window.addEventListener('resize', (e) => {
			this.manageScroll(true);
		});
		this.manageScroll(true);
	}
	update(skipCallScroll = false) {
		document
			.querySelectorAll('[data-scroll-fade-out]')
			.forEach((element) => {
				let offset = element.getAttribute('data-scroll-fade-out');
				offset = Number.parseInt(offset);
				offset = Number.isNaN(offset) ? 100 : offset;

				this.targets.push({
					element,
					offset,
				});
				element.removeAttribute('data-scroll-fade-out');
			});

		!skipCallScroll && this.manageScroll(true);
	}
	manageScroll(updateGeometries = false) {
		const scrollTop = window.pageYOffset;
		const winHeight = window.innerHeight;

		if (updateGeometries) {
			this.targets.forEach((target) => {
				const bBox = target.element.getBoundingClientRect();
				target.y = bBox.top + scrollTop;
			});
		}

		const viewTop = scrollTop;
		const viewBottom = viewTop + winHeight;

		this.targets.forEach((target) => {
			let scrollMax = target.y + target.offset - winHeight;
			scrollMax =
				scrollMax < target.offset ? target.offset : scrollMax;

			let alpha = (scrollMax - scrollTop) / target.offset;
			alpha = alpha < 0 ? 0 : alpha;
			alpha = alpha > 1 ? 1 : alpha;

			if (target.alpha !== alpha) {
				target.alpha = alpha;

				target.element.style.opacity = alpha;
			}
		});
	}
}

export default new ScrollFadeOuts();
