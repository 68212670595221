import LazyLoad from 'vanilla-lazyload';

import SectionStyles from "@js/modules/SectionStyles";
import PageScrollingManager from "@js/modules/PageScrollingManager";

class LazyLoadHelper {
	constructor(){
		this.llInstance = new LazyLoad({
			threshold: 400,
			callback_loaded: () => {
				SectionStyles.update(true);
				PageScrollingManager.update(true);
			}
		});
	}
	update(){
		this.llInstance.update();
	}
}

export default new LazyLoadHelper();
