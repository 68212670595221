import gsap from 'gsap';

class Onloaders {
	constructor() {
		// Uncomment to have it load when ready
		this.update();
	}
	init(el) {
		if (el.onloadersInited) {
			return;
		}

		gsap.fromTo(el, {alpha: 0}, {alpha: 1});
	}
	update(debug) {
		document
			.querySelectorAll('[data-onloader]')
			.forEach((el) => this.init(el));
	}
}
export default new Onloaders();
