// Imports
import PageScrolling from '@js/modules/PageScrolling';

class Contact {
	constructor(container) {
        setTimeout(() => {
            console.log('Contact');
            PageScrolling.start();
        }, 500);
	}
	leave(){

	}
}

export default Contact
