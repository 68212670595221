// Imports
import { gsap } from "gsap";

const OPENED = '_opened'
const DISABLED = '_expandable-disabled'

class Expandables {
	constructor() {
		this.rebuild()
	}
	rebuild(){
		document.querySelectorAll('[data-expandable]').forEach(el => {
			this._init(el);
		})
	}
	_init(container){
		let workWidth = Number.parseFloat(container.getAttribute('data-expandable'));
		let alwaysWork = Number.isNaN(workWidth);
		let allowWork;

		//container.removeAttribute('data-expandable');
		//container.style.outline = '1px solid red';

		//console.log(workWidth, alwaysWork)

		const target = container.querySelector('[data-expandable-target]')
		const trigger = container.querySelector('[data-expandable-trigger]')
		const opener = container.querySelector('[data-expandable-opener]')
		const closer = container.querySelector('[data-expandable-closer]')

		let opened = true;

		const open = () => {
			if(!allowWork){
				return;
			}
			opened = true;
			container.classList.add(OPENED)

			const currentHeight = target.getBoundingClientRect().height;
			// target.style.overflow = '';
			target.style.height = '';
			const finalHeight = target.getBoundingClientRect().height;
			const winHeight = window.innerHeight

			gsap.fromTo(target, {height: (currentHeight / winHeight * 100) + 'vh'}, {height: (finalHeight / winHeight * 100 ) + 'vh', clearProps: 'height', duration: 0.5})
		}
		const close = () => {
			if(!allowWork){
				return;
			}
			opened = false;
			container.classList.remove(OPENED)

			target.style.overflow = 'hidden';
			gsap.to(target, {height: '33vh', duration: 0.5})
		}

		trigger && trigger.addEventListener('click', e => {
			e.preventDefault();

			opened ? close () : open();
		})
		opener && opener.addEventListener('click', e => {
			e.preventDefault();

			!opened && open();
		})

		closer && closer.addEventListener('click', e => {
			e.preventDefault();

			opened && close();
		})

		if(!alwaysWork){
			const testAllowWork = () => {
				let newAllowWork;
				if(window.innerWidth > workWidth){
					newAllowWork = false;
				} else {
					newAllowWork = true;
				}
				if(allowWork !== newAllowWork){
					allowWork = newAllowWork;

					if(!allowWork){
						container.classList.add(DISABLED)

						gsap.killTweensOf(target);
						target.style.height = '';
						target.style.overflow = '';
					} else {
						container.classList.remove(DISABLED)

						gsap.killTweensOf(target);
						target.style.overflow = 'hidden';
						target.style.height = '33vh';

						opened = false;
					}
				}
			}
			window.addEventListener('resize', testAllowWork);
			testAllowWork();
		} else {
			allowWork = true
		}
	}
}

export default new Expandables();
