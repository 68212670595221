// Project imports
import PageScrolling from '@js/modules/PageScrolling';
import Search from "@js/modules/Search";

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_nav-open';

const USE_ADDRESS_NAVIGATION = window.useSectionAddresses;

class Navigation {
    constructor() {
        this.opened = false;

        this.element = document.getElementById('mobile-nav');
        this.mobileNav = document.getElementById('mobile-nav-menu');
        this.mobileNavBtns = this.mobileNav.querySelectorAll('a');
        this.opener = document.getElementById('mobile-nav-btn');

        if (this.opener) {
            this.opener.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle();
            });
        }

        window.addEventListener('keydown', (e) => {
            if (this.opened && e.keyCode == 27) {
                this.close();
            }
        });

        window.addEventListener('resize', e => {
			this.close();
		});

        for (let i = 0; i < this.mobileNavBtns.length; i++) {
            this.mobileNavBtns[i].addEventListener('click', (e) => {
                this.close();
            });
        }
    }
    open() {
        if (!this.opened) {
            this.opened = true;

            // Close search
            Search.close();

            HTML_CLASSLIST.add(CSS_OPENED);

            // Update ARIA label
            this.opener.setAttribute('aria-label', 'Close navigation');

            // Disable scroll
            PageScrolling.lock(this.element);
        }
    }
    close() {
        if (this.opened) {
            this.opened = false;

            HTML_CLASSLIST.remove(CSS_OPENED);

            // Update ARIA label
            this.opener.setAttribute('aria-label', 'Open navigation');

            // Enable scroll
            PageScrolling.unlock(this.element);
        }
    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new Navigation();
