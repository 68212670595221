// Imports
import Splide from '@splidejs/splide';

class Carousels {
    constructor() {

    }

    setup() {
        this.carousels = {};

        const carouselEls = document.querySelectorAll('.carousel');

        carouselEls.forEach((carousel, i) => {
            const carouselFullwidth = carousel.classList.contains('_full-width');
            const carouselFade = carousel.classList.contains('_fade');
            const customConfig = carousel.getAttribute('data-carousel-settings');
            let carouselAdditionalConfig = {};
            let carouselConfig = {
                arrowPath: 'M32.6 20.4c0 .6-.3 1.2-.8 1.6L11.1 39.3c-.9.8-2.2.6-3-.3-.8-.9-.6-2.2.3-3l18.8-15.7L7.6 3.9c-.9-.8-1-2.1-.3-3 .8-.9 2.1-1 3-.3l21.5 18.1c.5.5.8 1.1.8 1.7z',
                drag: true,
                gap: '24px',
                type: 'loop'
            };

            // Dont override splide config
            if (customConfig !== null) {
                carouselAdditionalConfig = JSON.parse(customConfig);
            } else {
                if (carouselFade) {
                    carouselAdditionalConfig = {
                        type: 'fade',
                        arrows: true,
                        autoplay: true,
                        drag: false,
                        gap: 0,
                        padding: 0,
                        pagination: true,
                        pauseOnHover: false,
                        perMove: 1,
                        perPage: 1,
                        rewind: true,
                    };
                } else {
                    // Full width slide
                    if (carouselFullwidth) {
                        carouselAdditionalConfig = {
                            padding: 0,
                            perMove: 1,
                            perPage: 1,
                        };
                    } else {
                        carouselAdditionalConfig = {
                            padding: '5%',
                            perMove: 1,
                            perPage: 4,
                            breakpoints: {
                                1200: {
                                    padding: '10%',
                                    perMove: 2,
                                    perPage: 1,
                                },
                                768: {
                                    padding: '15%',
                                    perMove: 1,
                                    perPage: 1,
                                },
                            }
                        };
                    }
                }
            }

            // Update config to include the additional config
            carouselConfig = {...carouselConfig, ...carouselAdditionalConfig};

            console.log({carouselConfig});

            this.carousels[i] = new Splide(carousel, carouselConfig);
            this.carousels[i].mount();
        });
    }
}

export default new Carousels();
