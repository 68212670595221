// Project imports
import env from '@js/utils/env';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import HTMLCreator from "@js/helpers/HTMLCreator";
import LazyLoadHelper from "@js/helpers/LazyLoadHelper";
import SectionStyles from '@js/modules/SectionStyles';

class AjaxLoaders {
    constructor() {
        this.scrollRatioThreshold = 0.9;
        this.scrollListener = this._handleScroll.bind(this);
        this.loading = false;
        this.domLoader = null;
        this.nextPageElement = null;
    }

    setup(container) {
        this.container = container;
        this.contentContainer = this.container.querySelector('[data-paginate]');

        if (!this.contentContainer) {
            return;
        }

        this.contentProgress = this.container.querySelector('[data-pagination-loaded]');
        this.contentTotal = this.contentProgress.getAttribute('data-pagination-loaded');
        this.nextPageElement = this.container.querySelector('[data-pagination-next]');
        this.updateProgress();

        // If pagination
        if (this.nextPageElement) {
            // Add new listener
            window.addEventListener('scroll', this.scrollListener);
        }
        else {
            // Remove existing listener
            window.removeEventListener('scroll', this.scrollListener);
        }
    }

    _handleScroll() {
        if (this.loading) {
            return;
        }

        const scrollRatio = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
        if (scrollRatio >= this.scrollRatioThreshold) {
            this.loading = true;
            window.removeEventListener('scroll', this.scrollListener);

            // Get loader - If one has been added to the DOM
            this.domLoader = document.getElementById('pagination-loader');

            if (this.domLoader) {
                // Show loader
                this.domLoader.classList.remove('hidden');
            } else {
                // Create the loader and show it
                this.domLoader = document.createElement('div');
                this.domLoader.textContent = 'Loading...';
                this.domLoader.setAttribute('id', 'pagination-loader');
                this.domLoader.setAttribute('class', 'controls fixed bottom-5 right-16 text-base z-90');
                document.body.appendChild(this.domLoader);
            }

            // Get next URL
            let nextURL = this.nextPageElement.getAttribute('data-pagination-next');
            // Test if URL has ajax param in it
            if (!nextURL.includes('ajax=1')) {
                // Update URL to include ajax param
                nextURL += (nextURL.includes('?') ? '&' : '?') + 'ajax=1';
            }

            let prevFeatured = this.nextPageElement.getAttribute('data-pagination-prev-featured');
            if (prevFeatured) {
                // Update URL to include previous-featured param
                nextURL += (nextURL.includes('?') ? '&' : '?') + 'previous-featured=true';
            }

            let curated = this.nextPageElement.getAttribute('data-pagination-curated');
            if (curated) {
                // Update URL to include previous-featured param
                nextURL += (nextURL.includes('?') ? '&' : '?') + 'curated=true';
            }

            console.log({ nextURL });

            // Remove previous next page element
            this.nextPageElement.parentNode.removeChild(this.nextPageElement);

            // Get new entries
            fetch(nextURL, {
                method: 'GET'
            })
                .then((response) => response.text())
                .then((data) => {
                    HTMLCreator.create(data, this.contentContainer);

                    if (!env.reducedMotion) {
                        Appear.start();
                        AppearParent.start();
                    }

                    LazyLoadHelper.update();

                    this.loading = false;
                    this.nextPageElement = this.container.querySelector('[data-pagination-next]');

                    if (this.nextPageElement) {
                        window.addEventListener('scroll', this.scrollListener);
                    }

                    this.updateProgress();

                    // Hide loader
                    this.domLoader.classList.add('hidden');

                    SectionStyles.rebuild();
                });
        }
    }


    updateProgress() {
        // Get new total
        const elementChildrenCount = this.contentContainer.querySelectorAll('[data-entry]').length;
        const newTotal = (elementChildrenCount / this.contentTotal) * 100;
        // Update progress indicator
        this.contentProgress.style.width = newTotal + '%';

        console.log({ elementChildrenCount });
    }
}

export default new AjaxLoaders();
