class HoverConnect {
    constructor() {
        document.querySelectorAll("[data-hover-connect]").forEach((el) => {
            this._init(el);
        });
    }
    _init(element) {
        let connectValue = element.getAttribute('data-hover-connect');

        element.addEventListener('mouseenter', (e) => {
            document.querySelectorAll('[data-hover-connect="' + connectValue + '"]').forEach((el) => {
                el.classList.add('_hover');
            });
        });
        element.addEventListener('mouseleave', (e) => {
            document.querySelectorAll('[data-hover-connect="' + connectValue + '"]').forEach((el) => {
                el.classList.remove('_hover');
            });
        });
    }
}

export default new HoverConnect();
