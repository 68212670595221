/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Imports
import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import reframe from 'reframe.js';

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import HTMLCreator from '@js/helpers/HTMLCreator';
import LazyLoadHelper from '@js/helpers/LazyLoadHelper';
import VhCalculator from '@js/helpers/VhCalculator';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Accordions from '@js/modules/Accordions';
import AjaxLoaders from '@js/modules/AjaxLoaders';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import Carousels from '@js/modules/Carousels';
import Expandables from '@js/modules/Expandables';
import HeroVideo from '@js/modules/HeroVideo';
import HoverConnect from '@js/modules/HoverConnect';
import Modal from '@js/modules/Modal';
import Navigation from '@js/modules/Navigation';
import Onloaders from '@js/modules/Onloaders';
import ProgressLine from '@js/modules/ProgressLine';
import ScrollFadeOuts from '@js/modules/ScrollFadeOuts';
import Search from '@js/modules/Search';
import SectionStyles from '@js/modules/SectionStyles';
import Tabs from '@js/modules/Tabs';

// Pages modules
import Contact from '@js/pages/Contact';
import Home from '@js/pages/Home';

const HTML_CLASSLIST = document.documentElement.classList;

window.RBA =
    window.RBA ||
    new (function RBA() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            HTMLCreator: HTMLCreator,
            LazyLoadHelper: LazyLoadHelper,
            VhCalculator: VhCalculator,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Accordions: Accordions,
            AjaxLoaders: AjaxLoaders,
            Appear: Appear,
            AppearParent: AppearParent,
            Carousels: Carousels,
            Expandables: Expandables,
            HeroVideo: HeroVideo,
            HoverConnect: HoverConnect,
            Modal: Modal,
            Navigation: Navigation,
            Onloaders: Onloaders,
            ProgressLine: ProgressLine,
            ScrollFadeOuts: ScrollFadeOuts,
            Search: Search,
            SectionStyles: SectionStyles,
            Tabs: Tabs,
        };

        // Pages
        this.pages = {
            contact: Contact,
            home: Home,
        };


        let currentController;
        let currentClasses;
        let currentNameSpace;

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Show progress line
            this.modules.ProgressLine.show();

            // Stop scroll
            this.modules.PageScrolling.lock(document.body);

            // Hide pagination elements
            let paginationNext = document.querySelector('[data-pagination-next]');
            let paginationLoader = document.getElementById('pagination-loader');

            if (paginationNext) {
                paginationNext.remove();
            }
            if (paginationLoader) {
                paginationLoader.remove();
            }

            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container) => {
            window.scrollTo(0,0);

            // Enable scrolling
            this.modules.PageScrolling.unlock(document.body);

            const cssVars = container.getAttribute('data-spa-css-vars');
            if (cssVars){
                cssVars.split(';').forEach(el => {
                    const varName = el.split(':')[0];
                    const varValue = el.split(':')[1];

                    if (varName){
                        document.documentElement.style.setProperty(varName, varValue);
                    }
                })
            }
            this.modules.ProgressLine.hide();

            this.modules.PageScrolling.start();
            this.modules.PageScrollingManager.update(true);

            if (!this.env.reducedMotion) {
                this.modules.Appear.start();
                this.modules.AppearParent.start();
            }

            this.modules.Onloaders.update();

            this.modules.AjaxLoaders.setup(container);

            this.modules.SectionStyles.rebuild();
            this.modules.Tabs.rebuild();
            this.modules.Accordions.rebuild();
            this.modules.Expandables.rebuild();

            this.modules.Modal.init();

            this.helpers.LazyLoadHelper.update();

            /**
             * Hero video
             */
            function videosLoadedCallback() {
                console.log('All videos have finished loading!');

                // Detect if motion is allowed and the device battery is charged enough
                if (!window.RBA.env.reducedMotion) {
                    const videoHeroEls = document.querySelectorAll('.hero-video');

                    videoHeroEls.forEach((video) => {
                        // Test for support for readyState, else fallback to canplay event
                        if (video.readyState >= video.HAVE_FUTURE_DATA) {
                            // Show video
                            video.classList.remove('opacity-0');
                            // Play video
                            video.play();
                        }
                        else {
                            video.addEventListener('canplay', () => {
                                // Show video
                                video.classList.remove('opacity-0');
                                // Play video
                                video.play();
                            }, false);
                        }
                    });
                }
            }

            HeroVideo.init(videosLoadedCallback);

            /**
             * Reframe
             */
            reframe('.reframe');

            /*
                Carousels
             */
            this.modules.Carousels.setup();

            const nameSpace = container.getAttribute('data-spa-namespace');
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                currentController = new pageController(container);
            }
            else {
                currentController = null;
            }

            if(currentClasses){
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                })
            }
            currentClasses = (container.getAttribute('data-spa-class') || '').split(' ')
            currentClasses.forEach(cssClass => {
                HTML_CLASSLIST.add(cssClass);
            })

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update anchors
            Array.from(document.querySelectorAll('a')).forEach((link) => {
                if (
                    this.utils.testHrefIsActual(link.getAttribute('href'))
                    || window.location.href.includes(link.href)
                ) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            gsap.delayedCall(1, () => {
                this.modules.SectionStyles.update(true);
            });
        };

        const start = (immediate = false) => {
            HTML_CLASSLIST.remove('_loading');
            HTML_CLASSLIST.remove('_loading-started');

            enterController(
                document.body.querySelector('[data-spa-namespace]'), true
            );
            HTML_CLASSLIST.add('_loaded');

            barba.init({
                schema: {
                    prefix: 'data-spa',
                },
                sync: true,
                prefetchIgnore: true,
                cacheIgnore: true,
                preventRunning: true,
                prevent: (data) => {
                    data.event.stopPropagation();

                    if (data.el.getAttribute('data-external') != null) {
                        return true;
                    }

                    data.event.preventDefault();
                    return false;
                },
                timeout: 30 * 1000,
                transitions: [
                    {
                        leave({ current, next, trigger }) {
                            const done = this.async();
                            leaveController(current.container, done);
                        },

                        beforeEnter({ current, next, trigger }) {
                            const done = this.async();
                           // imagesLoaded(next.container, () => {
                                done();
                           // });
                        },
                        after({ next }) {

                            // GA page view tracking
                            if (typeof gtag === 'function') {
                                gtag('event', 'page_view', {
                                    'page_title': document.title,
                                    'page_location': location.href,
                                    'page_path': location.pathname,
                                });
                            }

                            enterController(next.container);
                        },
                    },
                ],
            });
        };

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();

            /**
             * Page scroll button
             */
            let pageScrollButton = document.getElementById('page-scroll-button'),
                pageScrollMin = 700,
                pageScrollMax = 1500;

            if (pageScrollButton) {
                pageScrollButton.addEventListener('click', e => {
                    let scrollPos = document.documentElement.classList.contains('_scrolled') ? 0 : window.innerHeight,
                        scrollSpeed = 0;

                    // If not reduce motion, work out the scroll speed
                    if (!env.reducedMotion) {
                        if (window.pageYOffset >= pageScrollMin && window.pageYOffset <= pageScrollMax) {
                            scrollSpeed = 2
                        }
                        else if (window.pageYOffset > pageScrollMax) {
                            scrollSpeed = 1;
                        }
                        else {
                            // Cover all other cases
                            scrollSpeed = 1;
                        }
                    }

                    gsap.to(window, {
                        duration: scrollSpeed,
                        scrollTo: scrollPos
                    });
                });
            }
        });

        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);

            // Load
            this.modules.Onloaders.update();
        });
    })();
