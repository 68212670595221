const HTML_CLASSLIST = document.documentElement.classList;

class Home {
	constructor(container) {

	}
	leave(){

	}
}

export default Home
