// Project imports
import PageScrolling from '@js/modules/PageScrolling';
import Navigation from "@js/modules/Navigation";

const HTML_CLASSLIST = document.documentElement.classList;
const CSS_OPENED = '_search-open';

class Search {
    constructor() {
        this.opened = false;
        this.element = document.getElementById('search');
        this.opener = document.getElementById('search-btn');

        if (this.opener) {
            this.opener.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle();
            });
        }

        window.addEventListener('keydown', (e) => {
            if (this.opened && e.keyCode == 27) {
                this.close();
            }
        });

        window.addEventListener('resize', e => {
			this.close();
		});
    }
    open() {
        if (!this.opened) {
            this.opened = true;

            // Close navigation
            Navigation.close();

            HTML_CLASSLIST.add(CSS_OPENED);

            // Update ARIA label
            this.opener.setAttribute('aria-label', 'Close search');

            // Disable scroll
            PageScrolling.lock(this.element);
        }
    }
    close() {
        if (this.opened) {
            this.opened = false;

            HTML_CLASSLIST.remove(CSS_OPENED);

            // Update ARIA label
            this.opener.setAttribute('aria-label', 'Open search');

            // Enable scroll
            PageScrolling.unlock(this.element);
        }
    }
    toggle() {
        if (this.opened) {
            this.close();
        }
        else {
            this.open();
        }
    }
}

export default new Search();
