// Imports
import { gsap } from "gsap";

// Key codes - For easy reference
const keys = {
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46
};

// Add or substract depending on key pressed
const direction = {
    37: -1,
    38: -1,
    39: 1,
    40: 1
};

class Tabs {
    constructor() {
        this.rebuild()
    }
    rebuild(){
        document.querySelectorAll("[data-tabs]").forEach((el) => {
            this._init(el);
        });
    }
    _init(element) {
        if(element.tabsInited){
           return;
        }
        element.tabsInited = true;

        let tabs = element.querySelectorAll('[role="tab"]'),
            panels = element.querySelectorAll('[role="tabpanel"]'),
            delay = determineDelay();

        // Bind listeners
        for (let i = 0; i < tabs.length; ++i) {
            addListeners(i);
        }

        function addListeners(index) {
            tabs[index].addEventListener('click', clickEventListener);
            tabs[index].addEventListener('keydown', keydownEventListener);
            tabs[index].addEventListener('keyup', keyupEventListener);

            // Build an array with all tabs (<button>s) in it
            tabs[index].index = index;
        }

        // When a tab is clicked, activateTab is fired to activate it
        function clickEventListener(event) {
            let tab = event.target;

            activateTab(tab, false);
        };

        // Handle keydown on tabs
        function keydownEventListener(event) {
            let key = event.keyCode;



            switch (key) {
                case keys.end:
                    event.preventDefault();
                    // Activate last tab
                    activateTab(tabs[tabs.length - 1]);
                    break;
                case keys.home:
                    event.preventDefault();
                    // Activate first tab
                    activateTab(tabs[0]);
                    break;

                    // Up and down are in keydown
                    // because we need to prevent page scroll >:)
                case keys.up:
                case keys.down:
                    determineOrientation(event);
                    break;
            };
        };

        // Handle keyup on tabs
        function keyupEventListener(event) {
            let key = event.keyCode;

            switch (key) {
                case keys.left:
                case keys.right:
                    determineOrientation(event);
                    break;
                case keys.delete:
                    determineDeletable(event);
                    break;
            };
        };

        // When a tablist's aria-orientation is set to vertical,
        // only up and down arrow should function.
        // In all other cases only left and right arrow function.
        function determineOrientation(event) {
            let key = event.keyCode,
                vertical = element.getAttribute('aria-orientation') == 'vertical',
                proceed = false;

            if (vertical) {
                if (key === keys.up || key === keys.down) {
                    event.preventDefault();
                    proceed = true;
                };
            }
            else {
                if (key === keys.left || key === keys.right) {
                    proceed = true;
                };
            };

            if (proceed) {
                switchTabOnArrowPress(event);
            };
        };

        // Either focus the next, previous, first, or last tab
        // depending on key pressed
        function switchTabOnArrowPress(event) {
            let pressed = event.keyCode;

            for (let x = 0; x < tabs.length; x++) {
                tabs[x].addEventListener('focus', focusEventHandler);
            };

            if (direction[pressed]) {
                let target = event.target;
                if (target.index !== undefined) {
                    if (tabs[target.index + direction[pressed]]) {
                        tabs[target.index + direction[pressed]].focus();
                    }
                    else if (pressed === keys.left || pressed === keys.up) {
                        focusLastTab();
                    }
                    else if (pressed === keys.right || pressed === keys.down) {
                        focusFirstTab();
                    };
                };
            };
        };

        // Activates any given tab panel
        function activateTab(tab, setFocus) {
            setFocus = setFocus || true;

            // Do nothing if tab is active
            if (tab.getAttribute('aria-selected') === 'true') {
                return
            }

            // Deactivate all other tabs
            deactivateTabs();

            // Remove tabindex attribute
            tab.removeAttribute('tabindex');

            // Set the tab as selected
            tab.setAttribute('aria-selected', 'true');

            // Get the value of aria-controls (which is an ID)
            let controls = tab.getAttribute('aria-controls');

            // Remove hidden attribute from tab panel to make it visible
            const tabElement = document.getElementById(controls)
            tabElement.removeAttribute('hidden');

            gsap.fromTo(tabElement, {alpha: 0, 'will-change': 'opacity'}, {alpha: 1});

            // Set focus when required
            if (setFocus) {
                tab.focus();
            };
        };

        // Deactivate all tabs and tab panels
        function deactivateTabs() {
            for (let t = 0; t < tabs.length; t++) {
                tabs[t].setAttribute('tabindex', '-1');
                tabs[t].setAttribute('aria-selected', 'false');
                tabs[t].removeEventListener('focus', focusEventHandler);
            };

            for (let p = 0; p < panels.length; p++) {
                panels[p].setAttribute('hidden', 'hidden');
            };
        };

        // Focus first tab
        function focusFirstTab() {
            tabs[0].focus();
        }

        // Focus last tab
        function focusLastTab() {
            tabs[tabs.length - 1].focus();
        }

        // Detect if a tab is deletable
        function determineDeletable(event) {
            let target = event.target;

            if (target.getAttribute('data-deletable') !== null) {
                // Delete target tab
                deleteTab(event, target);

                // Update arrays related to tabs widget
                generateArrays();

                // Activate the closest tab to the one that was just deleted
                if (target.index - 1 < 0) {
                    activateTab(tabs[0]);
                }
                else {
                    activateTab(tabs[target.index - 1]);
                }
            }
        }

        // Deletes a tab and its panel
        function deleteTab(event) {
            let target = event.target,
                panel = document.getElementById(target.getAttribute('aria-controls'));

            target.parentElement.removeChild(target);
            panel.parentElement.removeChild(panel);
        }

        // Determine whether there should be a delay
        // when user navigates with the arrow keys
        function determineDelay() {
            let hasDelay = element.hasAttribute('data-delay'),
                delay = 0;

            if (hasDelay) {
                let delayValue = element.getAttribute('data-delay');

                if (delayValue) {
                    delay = delayValue;
                }
                else {
                    // If no value is specified, default to 300ms
                    delay = 300;
                };
            };

            return delay;
        }

        // Focus event handler
        function focusEventHandler(event) {
            let target = event.target;

            setTimeout(checkTabFocus, delay, target);
        }

        // Only activate tab on focus if it still has focus after the delay
        function checkTabFocus(target) {
            let focused = document.activeElement;

            if (target === focused) {
                activateTab(target, false);
            };
        }
    }
}

export default new Tabs();
