// Imports
import MicroModal from 'micromodal';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class Modal {
    constructor() {
        this.init();
    }
    init() {
        MicroModal.init({
            openClass: 'is-open', // Custom class to be applied when modal is open
            openTrigger: 'data-modal-open', // Custom data attribute to open modal
            closeTrigger: 'data-modal-close', // Custom data attribute to close modal
            onShow: modal => {
                // This is fired when the modal is opening.
                // The function receives the modal object as the first parameter and the trigger element as second parameter.

                // Disable scrolling
                PageScrolling.lock(modal);

                // Autoplay video
                this.autoplayVideo(modal);
            },
            onClose: modal => {
                //This is fired when the modal is closing.
                // The function receives the modal object as the first parameter and the trigger element as second parameter.

                // Enable scrolling
                PageScrolling.unlock(modal);

                // Stop video
                this.stopVideo(modal);
            }
        });
    }

    autoplayVideo(modal) {
        // Look for a YouTube, Vimeo, or HTML5 video in the modal
        var video = modal.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video');

        // Bail if the modal doesn't have a video
        if (!video)
            return;

        // If an HTML5 video, play it
        if (video.tagName.toLowerCase() === 'video') {
            video.play();
            return;
        }

        // Add autoplay to video src
        // video.src: the current video `src` attribute
        // (video.src.indexOf('?') < 0 ? '?' : '&'): if the video.src already has query string parameters, add an "&". Otherwise, add a "?".
        // 'autoplay=1': add the autoplay parameter
        video.src = video.src + (video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';
    }

    stopVideo(modal) {
        // Look for a YouTube, Vimeo, or HTML5 video in the modal
        var video = modal.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video');

        // Bail if the modal doesn't have a video
        if (!video) return;

        // If an HTML5 video, pause it
        if (video.tagName.toLowerCase() === 'video') {
            video.pause();
            return;
        }

        // Remove autoplay from video src
        video.src = video.src.replace('&autoplay=1', '').replace('?autoplay=1', '');
    }
}

export default new Modal();
